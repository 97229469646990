const groups = {christmasToys: 'Набор ёлочных игрушек'}
exports.listProducts = {
    toys: [{
        group: groups.christmasToys,
        name: 'Синица и зарянка',
        collection: 'Пыльца',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ['Воробей - 9х5 см ', 'Зарянка - 9х5 см']
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-sinitsa-i-zaryanka-205649830-10021435',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/pollen/tit_and_robin/1.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/pollen/tit_and_robin/2.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/pollen/tit_and_robin/3.jpg')]
            }, {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/pollen/tit_and_robin/4.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Лиса, петух и курочка',
        collection: 'Пыльца',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ['Лиса - 6х9 см', 'Петух - 9х9 см', 'Курочка - 8х8 см']
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/-nabor-yolochnykh-igrushek-lisa-petukh-i-kurochka-205649830-10021441',
        images: [
            {
                placement: '1:1',
                files: [
                    require('/assets/imgs/products/toys/pollen/fox_ch_roo/1.jpg')
                ]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/pollen/fox_ch_roo/2.jpg')]
            },
            {
                placement: '4:5',
                files: [
                    require('/assets/imgs/products/toys/pollen/fox_ch_roo/3.jpg'),
                    require('/assets/imgs/products/toys/pollen/fox_ch_roo/4.jpg')
                ]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/pollen/fox_ch_roo/5.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Усатое трио',
        collection: 'Мозайка',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ["Кот - 4х5 см"]
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['три деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-usatoe-trio-205649830-10021448',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/exclusive/cats/1.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/exclusive/cats/2.jpg'),
                    require('/assets/imgs/products/toys/exclusive/cats/3.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/exclusive/cats/4.jpg')]
            },

        ]
    }, {
        group: groups.christmasToys,
        name: 'Петух и воробей',
        collection: 'Зимние узоры',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ['Петух - 9х9 см', "Воробей - 9х5 см"]
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-quotpetukh-i-vorobeyquot-205649830-10018761',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/frost_patterns/rooster _sparrow/1.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/frost_patterns/rooster _sparrow/2.jpg'),
                    require('/assets/imgs/products/toys/frost_patterns/rooster _sparrow/3.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/frost_patterns/rooster _sparrow/4.jpg')]
            },

        ]
    }, {
        group: groups.christmasToys,
        name: 'Кот и воробей',
        collection: 'Пыльца',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ['Кот 5х4 см', 'Воробей 8х5 см']
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-quotkot-i-vorobeyquot-205649830-10018639',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/pollen/cat_sparrow/1.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/pollen/cat_sparrow/2.jpg'),
                    require('/assets/imgs/products/toys/pollen/cat_sparrow/3.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/pollen/cat_sparrow/4.jpg')]
            },

            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/pollen/cat_sparrow/5.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Три матроса',
        collection: 'Море',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ['Лиса - 6х9 см', 'Кот - 5х4 см', 'Конь - 6х5 см']
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['три деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-tri-matrosa-205649830-10021263',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/sea/three_sailors/1.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/sea/three_sailors/2.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/sea/three_sailors/3.jpg'),
                    require('/assets/imgs/products/toys/sea/three_sailors/4.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Кони',
        collection: 'Зимние узоры',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ['Конь - 6х5 см']
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-quotkoniquot-205649830-10018739',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/frost_patterns/horses/1.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/frost_patterns/horses/2.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/frost_patterns/horses/3.jpg'),
                    require('/assets/imgs/products/toys/frost_patterns/horses/4.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Лиса и две Забавы',
        collection: 'Зимние узоры',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ['Лиса - 6х9 см', "Курочка - 8х8 см"]
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['три деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-lisa-i-dve-zabavy-205649830-10021534',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/frost_patterns/2chicken_fox/1.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/frost_patterns/2chicken_fox/2.jpg')]
            }, {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/frost_patterns/2chicken_fox/3.jpg'),
                    require('/assets/imgs/products/toys/frost_patterns/2chicken_fox/4.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Кот, воробей и курочка',
        collection: 'Румяна',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ["Кот - 4х5 см", "Воробей - 8х5 см", "Курочка - 8х8 см"]
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['три деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-kot-vorobey-i-kurochka-205649830-10021517',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/red/cat_ch_bird/1.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/red/cat_ch_bird/2.jpg'),
                    require('/assets/imgs/products/toys/red/cat_ch_bird/3.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/red/cat_ch_bird/4.jpg')]
            },

        ]
    }, {
        group: groups.christmasToys,
        name: 'Молодожёны',
        collection: 'Зимние узоры',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ["Птичка - 10х3 см"]
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-molodozheny-205649830-10021514',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/frost_patterns/bullfinches/1.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/frost_patterns/bullfinches/2.jpg'),
                    require('/assets/imgs/products/toys/frost_patterns/bullfinches/3.jpg')]
            }, {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/frost_patterns/bullfinches/4.jpg'),
                    require('/assets/imgs/products/toys/frost_patterns/bullfinches/5.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Парочка',
        collection: 'Румяна',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ['Курочка - 8х8 см ', 'Петух - 9х9 см']
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-parochka-205649830-10021471',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/red/pare/1.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/red/pare/2.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/red/pare/3.jpg'),
                    require('/assets/imgs/products/toys/red/pare/4.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/red/pare/5.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Кот и курочка',
        collection: 'Румяна',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ['Кот - 4х5 см', "Курочка - 8х8 см"]
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-quotkot-i-kurochkaquot-205649830-10018747',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/red/cat_ch/1.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/red/cat_ch/2.jpg'),
                    require('/assets/imgs/products/toys/red/cat_ch/3.jpg')]
            }, {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/red/cat_ch/4.jpg'),
                    require('/assets/imgs/products/toys/red/cat_ch/5.jpg')]
            },


        ]
    }, {
        group: groups.christmasToys,
        name: 'Лиса и конь',
        collection: 'Зимние узоры',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ["Лиса - 6х9 см", "Конь - 6х5 см "]
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/-nabor-yolochnykh-igrushek-quotlisa-i-konquot-205649830-10021237',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/frost_patterns/fox_hors/1.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/frost_patterns/fox_hors/2.jpg'),
                    require('/assets/imgs/products/toys/frost_patterns/fox_hors/3.jpg')]
            }, {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/frost_patterns/fox_hors/4.jpg'),
                    require('/assets/imgs/products/toys/frost_patterns/fox_hors/5.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Лиса и воробей',
        collection: 'Румяна',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ["Лиса - 6х9 см", "Воробей - 8х5 см"]
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['две деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-lisa-i-vorobey-205649830-10021587',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/red/fox_bird/1.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/red/fox_bird/2.jpg'),
                ]
            }, {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/red/fox_bird/3.jpg'),
                    require('/assets/imgs/products/toys/red/fox_bird/4.jpg')]
            },
        ]
    }, {
        group: groups.christmasToys,
        name: 'Три круглых птички',
        collection: 'Пыльца',
        description: {
            size: {
                title: 'Ориентировочные размеры:',
                list: ["Птичка - 6х6 см"]
            },
            assortiment: {
                text: 'Набор для новогодней ели изготовлен из дерева, расписан акрилом, покрыт лаком.',
                title: 'В комплект набора входит:',
                list: ['три деревянные игрушки', 'золотые или серебряные нити', 'конверт с сюрпризом (авторские открытка-календарь и комплект наклеек)']
            }
        },
        link: 'product.html',
        linkVK: 'https://vk.com/market/product/nabor-yolochnykh-igrushek-quottri-kruglykh-ptichkiquot-205649830-10021292',

        images: [
            {
                placement: '1:1',
                files: [require('/assets/imgs/products/toys/pollen/3_birds/1.jpg')]
            },
            {
                placement: '2:3',
                files: [require('/assets/imgs/products/toys/pollen/3_birds/2.jpg')]
            },
            {
                placement: '4:5',
                files: [require('/assets/imgs/products/toys/pollen/3_birds/3.jpg'),
                    require('/assets/imgs/products/toys/pollen/3_birds/4.jpg')]
            },


        ]
    },],
}
;